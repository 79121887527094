import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'
import AnimatedHeading from 'components/animated-heading'

class ServicesTwo extends React.Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    const Section = styled.section`
            position: relative;
            overflow: hidden;
   
            background-image: url('/img/devops_kubernetes_infraestructure__goat_software_company_developer_wordpress_ecommerce_mobile.jpeg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position-x: right;
            .heading {
                width: 100%;
            }
          }
        `

    const ServiceContainer = styled.div`
      background-color: rgba(0, 0, 0, 0.6);
      padding: 100px 0;
    `

    const ServiceElement = styled.div`
      margin-bottom: 20px;
      text-align: center;
      padding: 40px;
      border-radius: 20px;
      transition: 0.2s;
      &:hover {
        background-color: #000;
        box-shadow: 0 28px 60px rgb(4, 229, 229, 0.1);
        transform: translateY(-10px);
      }
    `
    const ServiceHeading = styled.h4`
      font-size: 30px;
      font-weight: 500;
      font-family: Teko;
      color: #fff;
    `
    const ServiceSeparator = styled.div`
      height: 5px;
      width: 50px;
      background-color: #29aacb;
      margin-bottom: 10px;
      margin: auto;
    `
    const ServiceIcon = styled.div`
      margin-bottom: 20px;
      img {
        max-height: 70px;
      }
    `

    const ServiceList = styled.ul`
      padding: 0;
      margin: 11px 0 0 0;
    `

    const ServiceListElement = styled.li`
      list-style: none;
      color: #c5c5c5;
      font-weight: 300;
      font-size: 14px;
      margin: 5px 0px;
      &::before {
        display: inline-block;
        content: '';
        border-radius: 2.5px;
        height: 5px;
        width: 5px;
        margin-right: 10px;
        background-color: #29aacb;
        position: relative;
        bottom: 2.5px;
      }
    `

    return (
      <Section id='services'>
        <ServiceContainer>
          <Container>
            <AnimatedHeading text='INFRASTRUCTURE BUILDERS' />
            <Row>
              <Col md={4}>
                <AnimationContainer animation='fadeInLeft' delay={200}>
                  <ServiceElement>
                    <ServiceIcon>
                      <img
                        src='/static/cd1878fb2f0c3e4a27010144287f5ac0/46604/seo.png'
                        alt='Infrastructure builders'
                      />
                    </ServiceIcon>
                    <ServiceHeading>Cloud</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceList>
                      <ServiceListElement>GCP</ServiceListElement>
                      <ServiceListElement>AWS</ServiceListElement>
                      <ServiceListElement>Azure</ServiceListElement>
                    </ServiceList>
                  </ServiceElement>
                </AnimationContainer>
              </Col>
              <Col md={4}>
                <AnimationContainer animation='fadeInDown' delay={400}>
                  <ServiceElement>
                    <ServiceIcon>
                      <img
                        src={
                          'https://sue.nl/wp-content/uploads/2022/02/HashiCorp_Terraform_Logo_Wit.png'
                        }
                        alt='Automation'
                      />
                    </ServiceIcon>
                    <ServiceHeading>Automation</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceList>
                      <ServiceListElement>Terraform</ServiceListElement>
                      <ServiceListElement>Bash scripting</ServiceListElement>
                      <ServiceListElement>API's</ServiceListElement>
                    </ServiceList>
                  </ServiceElement>
                </AnimationContainer>
              </Col>
              <Col md={4}>
                <AnimationContainer animation='fadeInRight' delay={600}>
                  <ServiceElement>
                    <ServiceIcon>
                      <img
                        src={
                          'https://www.docker.com/wp-content/uploads/2022/03/horizontal-logo-monochromatic-white.png'
                        }
                        alt='Mobile App Development'
                      />
                    </ServiceIcon>
                    <ServiceHeading>Containers</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceList>
                      <ServiceListElement>Kubernetes</ServiceListElement>
                      <ServiceListElement>Docker</ServiceListElement>
                      <ServiceListElement>Docker Swarm</ServiceListElement>
                    </ServiceList>
                  </ServiceElement>
                </AnimationContainer>
              </Col>
            </Row>
          </Container>
        </ServiceContainer>
      </Section>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        background: file(relativePath: { eq: "bg2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              src
            }
          }
        }
        web: file(relativePath: { eq: "icons/web.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        mobile: file(relativePath: { eq: "icons/mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        seo: file(relativePath: { eq: "icons/seo2.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        email: file(relativePath: { eq: "icons/email.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        ui: file(relativePath: { eq: "icons/sketch.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        network: file(relativePath: { eq: "icons/network.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }
    `}
    render={({ background, web, mobile, seo, email, ui, network }) => (
      <ServicesTwo
        background={background}
        web={web}
        mobile={mobile}
        seo={seo}
        email={email}
        ui={ui}
        network={network}
        {...props}
      />
    )}
  />
)
