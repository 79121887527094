import React from 'react'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Hero from 'sections/hero/Hero.js'
import AboutOne from 'sections/about/AboutOne.js'
import ServicesOne from 'sections/services/ServicesOne.js'
import ServicesTwo from 'sections/services/ServicesTwo.js'
import ServicesThree from 'sections/services/ServicesThree.js'
import ContactCreative from 'sections/contact/ContactCreative.js'

const Index = () => {

  return (
    <div>
      <Helmet>
        <title>Software Development and I.T. consultancy</title>
        <meta
          name='description'
          content={'Software Development and I.T. consultancy'}
        />
      </Helmet>
      <Layout
        isHome={true}
        sections={['home', 'about', 'services', 'contact']}
      >
        <Hero />
        <AboutOne />
        <ServicesOne />
        <ServicesTwo />
        <ServicesThree />
        <ContactCreative />
      </Layout>
    </div>
  )
}

export default Index

export const creativeOneQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`
